const dateInstance = $("#i_employee_hoursWorked-entryDate").datepicker({
  todayHighlight: true,
	autoclose: true
});

dateInstance.datepicker('update', new Date())


$(".datepicker-to-prev").click(() => {
	const currentParsedDate = Date.parse(dateInstance.datepicker('getDate'))
	const dayParsed = 86400000
	const newDate = new Date(currentParsedDate - dayParsed)
	dateInstance.datepicker('update', newDate)
})

$(".datepicker-to-next").click(() => {
	const currentParsedDate = Date.parse(dateInstance.datepicker('getDate'))
	const dayParsed = 86400000
	const newDate = new Date(currentParsedDate + dayParsed)
	dateInstance.datepicker('update', newDate)
})

$("#i_employee_hoursWorked-startTime").datetimepicker({
  format: "HH:mm",
});
$("#i_employee_hoursWorked-endTime").datetimepicker({
  format: "HH:mm",
});

let id = null;
$(".remove").click(function () {
  id = $(this).closest("tr");
  $(".modal").fadeIn();
});
$(".button-okay").click(function () {
  $(".modal").fadeOut();
  id.fadeOut();
});
$(".button-cancle").click(function () {
  $(".modal").fadeOut();
});
