function validate() {
  $('.ng-valid').each(function () {
    if ($.trim($(this).val()) !== "") {
      $("button[name='submit']").prop('disabled', false);
    } else {
      $("button[name='submit']").prop('disabled', true);
      return false;
    }
  })
}

$('.ng-valid').change(function () {
  validate()
});

$(function() {
  $('input[name="file"]').change(function () {
    if ($(this).val() !== '') {
      $('div[id="disabled"]').removeClass('disabled');
    } else {
      $('div[id="disabled"]').addClass('disabled');
    }
  });
});