$(function() {
  // search
  $('.js-search-purchase').on('input', function(){
    let searchText = $(this).val();
    let targetText;

    $('.js-target-purchase li').each(function() {
      targetText = $(this).text();

      if (targetText.indexOf(searchText) != -1) {
        $(this).removeClass('hidden');
      } else {
        $(this).addClass('hidden');
      }
    });
  });

  $('.js-search-purchase').on('change', function(){
    let searchText = $(this).val();
    let targetText;
    let valid = true
    $('.js-target-purchase li').each(function() {
      targetText = $(this).text();

      if (targetText === searchText) {
        valid = true
        return false;
      } else {
        valid = false
      }
    });
    if (!valid) {
      $('.js-select').text($('.js-target-purchase li.default').text())
      $('.js-search-purchase').val('')
    }
    validate()
  });


  // selection
  $('.js-target-purchase li').click(function() {
    const selectedItem = $(this).text();
    const targetSelect = $(this).parents('.js-dataBox').prev('.js-select');
    
    if ($(this).hasClass('default')) {
      $(".js-search-purchase").val('');
    } else {
      $(".js-search-purchase").val(selectedItem);
    }
    $('.js-target-purchase li').not(this).removeClass('selected');
    $(this).addClass('selected');

    targetSelect.text(selectedItem);
    $('.js-overlay').hide();
    $('.js-dataBox').slideUp('fast');
    validate()
  });
});