$(function() {
  const $jsSelect = $('.js-select');
  
  // accordion
  $jsSelect.click(function() {
    const targetBox = $(this).next('.js-dataBox');
    const targetOverlay = targetBox.children('.js-overlay');

    if(targetBox.is(':visible')) {
      targetOverlay.hide();
      targetBox.slideUp('fast');
    } else {
      targetOverlay.show();
      targetBox.slideDown('fast');
    }

    targetOverlay.click(function() {
      targetOverlay.hide();
      targetBox.slideUp('fast');
    });
  });
  function initSearchSelection(inputSelector, listSelector, titleSelector) {

    const input = $(inputSelector)
    const list = $(listSelector + ' li')
    const defaultItem = $(listSelector + ' li.default')
    const title = $(titleSelector)
    // search
    input.on('input', function(){
      let searchText = $(this).val();
      let targetText;

      list.each(function() {
        targetText = $(this).text();

        if (targetText.indexOf(searchText) != -1) {
          $(this).removeClass('hidden');
        } else {
          $(this).addClass('hidden');
        }
      });
    });

    input.on('change', function(){
      let searchText = $(this).val();
      let targetText;
      let valid = true
      list.each(function() {
        targetText = $(this).text();

        if (targetText === searchText) {
          valid = true
          title.text(input.val())
          return false;
        } else {
          valid = false
        }
      });
      if (!valid) {
        title.text(defaultItem.text())
        input.val('')
      }
      validate()
    });

    // selection
    list.click(function() {
      const selectedItem = $(this).text();
      const targetSelect = $(this).parents('.js-dataBox').prev(title);

      if ($(this).hasClass('default')) {
        input.val('');
      } else {
        input.val(selectedItem);
      }
      list.not(this).removeClass('selected');
      $(this).addClass('selected');

      targetSelect.text(selectedItem);
      $('.js-overlay').hide();
      $('.js-dataBox').slideUp('fast');
      validate()
    })
  }
  initSearchSelection(
    '.js-search--project-assign', 
    '.js-target-list--project-assign', 
    '.js-select--project-assign'
  )
  initSearchSelection(
    '.js-search--user-assign', 
    '.js-target-list--user-assign', 
    '.js-select--user-assign'
  )
});