$(function () {

  const duration = 500;

  function smoothScroll() {
    $('a[href^="#"]').click(function () {
      const href = $(this).attr("href");
      const target = $(href == "#" || href == "" ? 'html' : href);
      const position = target.offset().top;
      $('html, body').animate({scrollTop: position}, duration);
      return false;
    });
  }

  const isIE = document.documentMode && document.uniqueID;
  if (isIE) {
    objectFitImages();
  }
  smoothScroll();

});